import React, { useEffect } from "react";
import Header from "./components/Header";
import { Col, Row } from "antd";
import PredictionSetting from "./components/PredictionSetting";
import WithdrawSetting from "./components/WithdrawSetting";
import { useAppDispatch, useAppSelector } from "stores";
import { setting } from "stores/SettingSlice";
import VolumeBotSetting from "./components/VolumeBotSetting";
import RefSetting from "./components/RefSetting";
import DonateSetting from "./components/DonateSetting";
import MiningSetting from "./components/MiningSetting";

export default function SettingPage() {
  const dispatch = useAppDispatch();
  const { token } = useAppSelector((state) => state.profile);
  const { isRefresh } = useAppSelector((state) => state.setting);

  useEffect(() => {
    if (token) {
      dispatch(setting());
    }
  }, [token]);

  useEffect(() => {
    if (isRefresh) {
      dispatch(setting());
    }
  }, [isRefresh]);
  return (
    <div>
      <Header />
      <Row style={{ margin: 0 }} gutter={[16, 16]} className="p-15">
        <Col span={12}>
          <RefSetting />
        </Col>
        <Col span={12}>
          <MiningSetting />
          <WithdrawSetting />
        </Col>
      </Row>
    </div>
  );
}
